<script>
import simplebar from "simplebar-vue";
import i18n from "../i18n";

export default {
  data() {
    return {
      languages: [
        {
          flag: require("@/assets/images/flags/zh.jpg"),
          language: "zh",
          title: "简体中文",
        },
        {
          flag: require("@/assets/images/flags/zh-HK.jpg"),
          language: "zh-HK",
          title: "繁體中文",
        },
        {
          flag: require("@/assets/images/flags/en.jpg"),
          language: "en",
          title: "English",
        },
        {
          flag: require("@/assets/images/flags/ja.jpg"),
          language: "ja",
          title: "日本語",
        },
        {
          flag: require("@/assets/images/flags/es.jpg"),
          language: "es",
          title: "Español",
        },
      ],
      current_language: "zh",
      userinfo: [],
      noticelist: [],
      serialstatus: 0,
      supportserial: false
    };
  },
  components: { simplebar },
  mounted() {
    var that = this;
    that.userinfo = JSON.parse(localStorage.getItem("user"));
    if (localStorage.getItem("notice")) {
      that.noticelist = JSON.parse(localStorage.getItem("notice"));
    }
    if ("serial" in navigator) {
      that.supportserial = true
      if (localStorage.getItem("serial")) {
        that.serialstatus = localStorage.getItem("serial");
      }
    }
    // var languagecode = navigator.language;
    // if(languagecode==="zh-HK" || languagecode==="zh-TW" || languagecode==="zh-MO"){
    //   languagecode = "zh-HK"
    // }else{
    //   languagecode = languagecode.substr(0, 2);
    // }

    if (localStorage.getItem("language")) {
      that.current_language = localStorage.getItem("language");
      i18n.locale = localStorage.getItem("language");
    }
  },
  methods: {
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    setLanguage(locale) {
      i18n.locale = locale;
      this.current_language = i18n.locale;
      localStorage.setItem("language", locale);
    },
    getjurisdiction() {
      var that = this;
      that.$axios
        .post(that.apiuri, {
          action: "jurisdiction",
        })
        .then(function (response) {
          that.$root.jurisdiction = response.data.map;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    lang(value, data) {
      return this.$t(value, data);
    },
    readmessage(idx) {
      let newindex;
      let newarray = [];
      for (let i = 0; i < this.noticelist.length; i++) {
        if (i === idx) {
        } else {
          newarray.push(this.noticelist[i]);
        }
      }
      this.noticelist = newarray;
      localStorage.setItem("notice", JSON.stringify(this.noticelist));
    },
    checkupdate() {
      var that = this;
      that.$axios
        .post(that.apiuri, {
          action: "checkupdate",
        })
        .then(function (response) {
          if (response.data.status == 200) {
            that.$confirm(that.$t("update.status.have"), that.$t("update.status.tip"), {
              confirmButtonText: that.$t("update.status.update"),
              cancelButtonText: that.$t("update.status.cancel"),
              type: 'warning'
            }).then(() => {
              console.log("update")
              const loading = that.$loading({
                lock: true,
                text: that.$t("update.status.updating"),
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
              });
              that.$axios
                .post(that.apiuri, {
                  action: "checkupdate",
                  update: true,
                  debug: true
                })
                .then(function (response) {
                  loading.close();
                  if (response.data.status == 200) {
                    that.$message({
                      message: that.$t("update.status.success"),
                      type: "success",
                    });
                    setTimeout(() => {
                      window.location.reload();
                    }, 2000);
                  } else {
                    that.$message({
                      message: that.$t("update.status.fail"),
                      type: "error",
                    });
                  }
                })
                .catch(function (error) {
                  loading.close();
                  console.log(error);
                });
            }).catch(() => {
            });
          } else {
            that.$message({
              message: that.$t("update.status.noupdate"),
              type: "success",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async toggleNFC() {
      var that = this;
      if (that.serialstatus == 0) {
        that.serialstatus = 1;
        localStorage.setItem("serial", 1);
        that
          .$confirm(that.$t("setting.serial.tip"), that.$t("update.status.tip"), {
            confirmButtonText: that.$t("global.button.ok"),
            cancelButtonText: that.$t("global.button.cancel"),
            type: "warning",
          })
          .then(() => {
            that.openserial();
          })
          .catch(() => { });
      } else {
        that.serialstatus = 0;
        localStorage.setItem("serial", 0);
        setTimeout(() => {
          that.$message({
            message: that.$t("setting.serial.disable"),
            type: "success",
          });
          location.reload();
        }, 1000);
      }
    },

  },
  filters: {
    formatTimer: function (value) {
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
    },
    timeago: function (value, lang) {
      let now = Date.parse(new Date());
      let ago = now - value;
      let age = ago / 1000;
      if (age < 60) {
        return lang("global.time.sec", { time: age });
      }
      if (age >= 60 && age < 3600) {
        return lang("global.time.min", { time: Math.floor(age / 60) });
      }
      if (age >= 3600) {
        return lang("global.time.hor", { time: Math.floor(age / 3600) });
      }
      if (age >= 43200) {
        let date = new Date(value);
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? "0" + MM : MM;
        let d = date.getDate();
        d = d < 10 ? "0" + d : d;
        let h = date.getHours();
        h = h < 10 ? "0" + h : h;
        let m = date.getMinutes();
        m = m < 10 ? "0" + m : m;
        let s = date.getSeconds();
        s = s < 10 ? "0" + s : s;
        return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
      }
    },
  },
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <a href="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/logo-sm.png" alt height="32" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/adminlogo-dark.png" alt height="50" />
            </span>
          </a>

          <a href="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/logo-sm.png" alt height="32" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/adminlogo-light.png" alt height="50" />
            </span>
          </a>
        </div>

        <button @click="toggleMenu" type="button" class="btn btn-sm px-3 font-size-24 header-item waves-effect"
          id="vertical-menu-btn">
          <i class="ri-menu-2-line align-middle"></i>
        </button>

        <div class="d-lg-inline-block ml-1">
          <el-tooltip class="item" effect="dark" :content="$t('system.serial.switch')" placement="top">
            <button type="button" class="btn header-item noti-icon waves-effect" @click="toggleNFC">
              <i class="mdi mdi-cellphone-nfc" v-if="serialstatus == 0"></i>
              <i class="mdi mdi-cellphone-nfc-off" v-else-if="serialstatus == 1"></i>
            </button>
          </el-tooltip>
        </div>
      </div>

      <div class="d-flex">
        <b-dropdown variant="white" right toggle-class="header-item">
          <template v-slot:button-content>
            <img class :src="require('@/assets/images/flags/' + current_language + '.jpg')
              " alt="Header Language" height="16" />
          </template>
          <b-dropdown-item class="notify-item" v-for="(entry, i) in languages" :key="`Lang${i}`" :value="entry"
            @click="setLanguage(entry.language)" :link-class="{ active: entry.language === current_language }">
            <img :src="`${entry.flag}`" alt="user-image" class="mr-1" height="12" />
            <span class="align-middle">{{ entry.title }}</span>
          </b-dropdown-item>
        </b-dropdown>

        <div class="dropdown d-none d-lg-inline-block ml-1">
          <button type="button" class="btn header-item noti-icon waves-effect" @click="initFullScreen">
            <i class="ri-fullscreen-line"></i>
          </button>
        </div>
        <div class="dropdown d-none d-lg-inline-block ml-1">
          <button type="button" class="btn header-item noti-icon waves-effect" @click="checkupdate">
            <i class=" ri-history-line"></i>
          </button>
        </div>

        <b-dropdown right menu-class="dropdown-menu-lg p-0" toggle-class="header-item noti-icon" variant="black">
          <template v-slot:button-content>
            <i class="ri-notification-3-line"></i>
            <span class="noti-dot" v-if="noticelist.length > 0"></span>
          </template>
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">
                  {{ $t("navbar.dropdown.notification.text") }}
                </h6>
              </div>
              <div class="col-auto">
                <a href="/messages" class="small">{{
                  $t("navbar.dropdown.notification.subtext")
                }}</a>
              </div>
            </div>
          </div>
          <simplebar style="max-height: 230px">
            <a href="javascript:;" class="text-reset notification-item" v-for="(val, idx) in noticelist" :key="idx"
              @click="readmessage(idx)">
              <div class="media">
                <div class="media-body" v-if="val.type2 === 'device'">
                  <h6 class="mt-0 mb-1">
                    {{
                      $t("global.notice.devicev2", {
                        status: $t("global.notice." + val.type1),
                      })
                    }}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{ $t("global.notice.device", { device: val.edid }) }}
                      {{ $t("global.notice." + val.type1) }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{ val.time | timeago(lang) }}
                    </p>
                  </div>
                </div>
                <div class="media-body" v-else-if="val.type2 === 'service'">
                  <h6 class="mt-0 mb-1">
                    {{
                      $t("global.notice.servicev2", {
                        status: $t("global.notice." + val.type1),
                      })
                    }}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{
                        $t("global.notice.service", {
                          service: $t("global.notice.server." + val.service),
                        })
                      }}
                      {{ $t("global.notice." + val.type1) }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{ val.time | timeago(lang) }}
                    </p>
                  </div>
                </div>
                <div class="media-body" v-else-if="val.type2 === 'stock'">
                  <h6 class="mt-0 mb-1">
                    {{
                      $t("global.notice.stock", {
                        edid: val.edid,
                        stock: val.stock,
                      })
                    }}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{ $t("global.notice.device", { device: val.edid }) }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{ val.time | timeago(lang) }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </simplebar>
          <div class="p-2 border-top">
            <a class="btn btn-sm btn-link font-size-14 btn-block text-center" href="/messages">
              <i class="mdi mdi-arrow-right-circle mr-1"></i>
              {{ $t("navbar.dropdown.notification.button") }}
            </a>
          </div>
        </b-dropdown>

        <b-dropdown right variant="black" toggle-class="header-item" class="d-inline-block user-dropdown">
          <template v-slot:button-content>
            <img class="rounded-circle header-profile-user" :src="'https://admin.aicoiot.beer/headimg/' +
              userinfo.username +
              '.svg'
              " alt="Header Avatar" />
            <span class="d-none d-xl-inline-block ml-1">{{
              userinfo.username
            }}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </template>
          <!-- item-->
          <!-- <a class="dropdown-item" href="#">
            <i class="ri-user-line align-middle mr-1"></i>
            {{ $t('navbar.dropdown.kevin.list.profile') }}
          </a> -->
          <!-- <a class="dropdown-item" href="#">
            <i class="ri-wallet-2-line align-middle mr-1"></i>
            {{ $t('navbar.dropdown.kevin.list.mywallet') }}
          </a> -->
          <!-- <a class="dropdown-item d-block" href="#">
            <span class="badge badge-success float-right mt-1">11</span>
            <i class="ri-settings-2-line align-middle mr-1"></i>
            {{ $t('navbar.dropdown.kevin.list.settings') }}
          </a> -->
          <!-- <a class="dropdown-item" href="#">
            <i class="ri-lock-unlock-line align-middle mr-1"></i>
            {{ $t('navbar.dropdown.kevin.list.lockscreen') }}
          </a> -->
          <!-- <div class="dropdown-divider"></div> -->
          <a class="dropdown-item text-danger" href="/logout">
            <i class="ri-shut-down-line align-middle mr-1 text-danger"></i>
            {{ $t("navbar.logout") }}
          </a>
        </b-dropdown>
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
.notify-item {
  .active {
    color: #16181b;
    background-color: #f8f9fa;
  }
}
</style>